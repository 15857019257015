/* .email-composer {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.logo {
  width: 180px;
  height: 50px;
  margin-right: 20px;
}

.form-container {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 20px;
}

.form-control {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.editor-container {
  height: 300px;
  margin-bottom: 20px;
}

.btn {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-primary {
  background-color: #007bff;
}

.btn-primary:hover {
  background-color: #0056b3;
} */


.email-composer {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  border-radius: 12px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid rgba(0, 0, 0, 0.05);
}

.logo {
  width: 80px;
  height: 80px;
  margin-right: 1rem;
  border-radius: 10px;
  object-fit: cover;
}

.header h1 {
  color: #2c3e50;
  font-weight: 600;
  margin: 0;
}

.form-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group label {
  margin-bottom: 0.5rem;
  color: #34495e;
  font-weight: 500;
}

.form-control {
  padding: 0.75rem;
  border: 1px solid #bdc3c7;
  border-radius: 6px;
  transition: border-color 0.3s ease;
}

.form-control:focus {
  outline: none;
  border-color: #3498db;
  box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
}

.editor-container {
  min-height: 300px;
  border-radius: 8px;
  background: white;
}

.ql-toolbar {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.attach-button, .send-button {
  padding: 0.75rem 1.5rem;
  border-radius: 6px;
  font-weight: 600;
  transition: all 0.3s ease;
}

.attach-button {
  background-color: #ecf0f1;
  color: #2c3e50;
  border: 1px solid #bdc3c7;
}

.attach-button:hover {
  background-color: #dde4e6;
}

.send-button {
  background-color: #3498db;
  color: white;
  border: none;
}

.send-button:hover {
  background-color: #2980b9;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.uploaded-files {
  background-color: #f8f9fa;
  border-radius: 6px;
  padding: 1rem;
}

.uploaded-files h4 {
  margin-top: 0;
  color: #2c3e50;
}

.uploaded-files ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.uploaded-files li {
  background-color: #ecf0f1;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  border-radius: 4px;
}

.upload-message {
  color: #27ae60;
  font-weight: 500;
}

@media (max-width: 600px) {
  .email-composer {
    padding: 1rem;
  }
  
  .header {
    flex-direction: column;
    text-align: center;
  }
  
  .logo {
    margin-right: 0;
    margin-bottom: 1rem;
  }
}