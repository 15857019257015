/* App.css 

.app-container {
  font-family: 'Arial', sans-serif;
}

.navbar {
  background: #333;
  color: #fff;
  padding: 10px;
  text-align: center;
}

.logo {
  max-width: 200px;
  margin-bottom: 20px;
}

.nav-link {
  color: #fff;
  margin: 0 15px;
  text-decoration: none;
  font-size: 18px;
}

.nav-link:hover {
  text-decoration: underline;
}

.container {
  margin: 20px;
} */

/* App.css */

.app-container {
  font-family: 'Arial', sans-serif;
  padding: 10px;
}

.navbar {
  background: #333;
  color: #fff;
  padding: 10px;
  text-align: center;
  display: flex;
  flex-direction: column; /* Stack items on mobile */
  align-items: center;
}

.logo {
  max-width: 100%; /* Adjust to fit the container */
  height: auto;
  margin-bottom: 10px;
}

.nav-link {
  color: #fff;
  margin: 5px 0; /* Stack links on mobile */
  text-decoration: none;
  font-size: 16px;
}

.nav-link:hover {
  text-decoration: underline;
}

.container {
  margin: 10px;
}

/* Media queries for larger screens */
@media (min-width: 600px) {
  .navbar {
    flex-direction: row; /* Horizontal layout on larger screens */
    justify-content: center;
  }

  .nav-link {
    margin: 0 15px;
    font-size: 18px;
  }

  .container {
    margin: 20px;
  }
}
